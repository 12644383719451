import { RouteObject } from 'react-router-dom'

import { HomeView } from './HomeView'
import { HomePage } from './pages/HomePage'

export const routesHome: RouteObject = {
  path: '',
  element: <HomeView />,
  children: [
    {
      path: '',
      element: <HomePage />
    }
  ]
}
