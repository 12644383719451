import { authorize, oAuthConfig } from '@petr-jilek/letssport-common'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

export function App() {
  useEffect(() => {
    if (localStorage.getItem(oAuthConfig.accessTokenKey)) return
    if (window.location.pathname === '/auth/login') return

    authorize()
  }, [])

  return (
    <div
      style={{
        padding: '20px'
      }}
    >
      <Outlet />
      <ToastContainer />
    </div>
  )
}
