export function HomePage() {
  //   const { loading, response } = useApi({
  //     method: apiAdminClient.api.accessibilityGetUserClaimRequestsList,
  //     callOnMount: true,
  //     initLoading: true
  //   })

  //   if (loading) return <p>Loading...</p>

  return (
    <div>
      <h1>Home page</h1>

      {/* {response.data?.map((claimRequest) => (
        <div key={claimRequest.id}>
          <p>{claimRequest.id}</p>
          <p>{claimRequest.email}</p>
          <p>{claimRequest.claim}</p>
          <p>{claimRequest.status}</p>
        </div>
      ))} */}
    </div>
  )
}
