import { getToken } from '@petr-jilek/letssport-common'
import { useEffect } from 'react'

export function LoginPage() {
  useEffect(() => {
    getToken()
  }, [])

  return <div>Authorizing</div>
}
