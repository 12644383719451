import { LogoutButton, apiAuthClient, authorize, claims, useApi } from '@petr-jilek/letssport-common'
import { Link, Outlet } from 'react-router-dom'

export function HomeView() {
  const { loading, response } = useApi({
    method: apiAuthClient.api.usersGetUserInfoList,
    callbackBeforeStopLoading: async (response) => {
      if (!response.data?.claims?.includes(claims.ADMIN)) {
        authorize()
      }
    },
    callOnMount: true,
    initLoading: true
  })

  if (loading) return <p>Loading...</p>

  return (
    <div>
      <nav>
        <p>{response.data?.email}</p>
        <LogoutButton />

        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
        </ul>
      </nav>

      <Outlet />
    </div>
  )
}
