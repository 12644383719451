import { RouteObject } from 'react-router-dom'

import { AuthView } from './AuthView'
import { LoginPage } from './pages/LoginPage'

export const routesAuth: RouteObject = {
  path: 'auth',
  element: <AuthView />,
  children: [
    {
      path: 'login',
      element: <LoginPage />
    }
  ]
}
