import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'

import { routesAuth } from '../modules/auth/routes'
import { routesError } from '../modules/error/routes'
import { routesHome } from '../modules/home/routes'
import { App } from './App'

export function AppRouter() {
  const router = createBrowserRouter([
    {
      path: '',
      element: <App />,
      children: [
        routesHome,
        routesAuth,
        routesError,
        {
          path: '*',
          element: <Navigate to="/404" replace />
        }
      ]
    }
  ])

  return <RouterProvider router={router} />
}
